import React from 'react'
import { withTranslation } from 'react-i18next'
import Slider from 'react-slick'

import I18n from '../components/i18n'
import Layout from '../components/layout'
import SEO from '../components/seo'

const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 400,
  centerMode: true,
  centerPadding: '120px',
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        centerPadding: '60px'
      }
    },
    {
      breakpoint: 480,
      settings: {
        centerMode: false
      }
    }
  ]
}

class JocPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = { language: props.i18n.language || 'vi' }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ language: nextProps.i18n.language })
  }

  render() {
    return (
      <Layout>
        <SEO title={this.props.t('joc.meta_title')} keywords={[`agriculture`, `cultivate`, `japan`, `vietnam`, `pepper`]} />
        <div className="page">
          <div className="container grid-lg">
            {
              this.state.language === 'vi' ?
                (
                  <React.Fragment>
                    <p>
                      <img className="img-responsive" src={require('../images/guide_1.jpg')}/>
                    </p>
                    <p>
                      <img className="img-responsive" src={require('../images/guide_2.jpg')}/>
                    </p>
                  </React.Fragment>
                ) : ''
            }
            <h1 className="page-title" dangerouslySetInnerHTML={{ __html: this.props.t('joc.title') }}></h1>
            <div className="img-as-bg" style={{
              backgroundImage: `url(${require('../images/joc_01.jpg')})`
            }}></div>
            <p>{this.props.t('joc.sentence4')}</p>
            <p>{this.props.t('joc.sentence5')}</p>
            <p>{this.props.t('joc.sentence6')}</p>
            <p>{this.props.t('joc.sentence7')}</p>
            <p>{this.props.t('joc.sentence8')}</p>
            <p>{this.props.t('joc.sentence9')}</p>
            <h2 className="page-subtitle">{this.props.t('joc.title1')}</h2>
            <p>{this.props.t('joc.sentence10')}</p>
            <p>{this.props.t('joc.sentence11')}</p>
            <p>{this.props.t('joc.sentence12')}</p>
            <p><strong>{this.props.t('joc.sentence13')}</strong></p>
            <p><a href="https://www.facebook.com/organic.college" target="_blank" rel="noopener noreferrer">https://www.facebook.com/organic.college</a></p>
            <p><a href="http://hiroshima-ouen.com/1501" target="_blank" rel="noopener noreferrer">http://hiroshima-ouen.com/1501</a></p>
            <p>{this.props.t('joc.sentence14')}</p>
            <p>{this.props.t('joc.sentence15')}</p>
            <div className="columns">
              <div className="column col-6 col-xs-12">
                <div className="img-as-bg" style={{
                  backgroundImage: `url(${require('../images/joc_08.jpg')})`
                }}></div>
              </div>
              <div className="column col-6 col-xs-12">
                <div className="img-as-bg" style={{
                  backgroundImage: `url(${require('../images/joc_09.jpg')})`
                }}></div>
              </div>
            </div>
            <p>{this.props.t('joc.sentence16')}</p>
            <div className="columns">
              <div className="column col-6 col-xs-12">
                <div className="img-as-bg" style={{
                  backgroundImage: `url(${require('../images/joc_02.jpg')})`
                }}></div>
              </div>
              <div className="column col-6 col-xs-12">
                <div className="img-as-bg" style={{
                  backgroundImage: `url(${require('../images/joc_03.jpg')})`
                }}></div>
              </div>
            </div>
            <h2 className="page-subtitle">{this.props.t('joc.title2')}</h2>
            <p>{this.props.t('joc.sentence17')}</p>
            <p>{this.props.t('joc.sentence18')}</p>
            <Slider {...sliderSettings}>
              {
                [
                  {
                    id: 1,
                    url: require('../images/joc_04.jpg'),
                  },
                  {
                    id: 2,
                    url: require('../images/joc_05.jpg'),
                  },
                  {
                    id: 3,
                    url: require('../images/joc_06.jpg'),
                  },
                  {
                    id: 4,
                    url: require('../images/joc_07.jpg'),
                  },
                  {
                    id: 5,
                    url: require('../images/joc_10.jpg'),
                  },
                  {
                    id: 6,
                    url: require('../images/joc_11.jpg')
                  }
                ].map(item => (
                  <div className="joc-item" key={item.id}>
                    <div className="joc-item-img" style={{ backgroundImage: `url(${item.url})` }}>
                    </div>
                  </div>
                ))
              }
            </Slider>
          </div>
        </div>
      </Layout>
    )
  }
}

export default withTranslation()(JocPage)
